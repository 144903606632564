<template>
    <form ref="form" method="post" action="https://payu.nicepay.co.kr/main.do" accept-charset="euc-kr">
        <input type="text" name="MID" :value="mid" />
        <input type="text" name="MallUserID" :value="mallUserId" />
        <input type="text" name="UserKey" />
        <input type="text" name="LogoImgUrl" :value="logoUrl" />
        <input type="text" name="EdiDate" />
        <input type="text" name="PageType" value="MyPage" />
        <input type="text" name="ReturnUrl" :value="returnUrl" />
        <input type="text" name="SignData" />
        <input type="text" name="SkinType" value="dark" />
        <input type="text" name="CharSet" value="euc-kr" />
    </form>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

export default {
    data() {
        return {
            mid: null,
            mallUserId: null, //        가맹점 아이디
            hashString: null, //        위변조 구분 문자열
        };
    },
    computed: {
        ...mapState(["user"]),
        baseUrl() {
            let { protocol, host } = window.location;
            protocol = protocol.replace(/[^a-z]/g, "");
            return `${protocol}://${host}`;
        },
        logoUrl() {
            return `${this.baseUrl}/images/logo.svg`;
        },
        returnUrl() {
            return `${this.baseUrl}/api/plugins/nice/payu/setting/response`;
        },
    },
    async mounted() {
        window.nicepayuComplete = (payload) => {
            window?.parent?.nicepayuComplete(payload);
            window?.opener?.nicepayuComplete(payload);
        };

        let { mid } = await api.plugins.nice.payu.config.get();

        this.mid = mid;
        this.mallUserId = this.user?.username;
        this.submit();
    },
    methods: {
        async submit() {
            let { ediDate, signData, userKey } = await api.plugins.nice.payu.setting.generate();

            this.$nextTick((_) => {
                var form = this.$refs.form;

                form.EdiDate.value = ediDate;
                form.SignData.value = signData;
                form.UserKey.value = userKey;

                if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                    // 모바일 환경일때 처리
                } else {
                    // PC 환경일때 처리
                    const popup = window.open("about:blank", "nice-payu", `width=420,height=671,status=no,scrollbars=no,resizable=no,left=${Math.round(screen.width / 2) - 210},top=${Math.round(screen.height / 2) - 335}`);

                    if (!popup) {
                        alert("팝업이 차단되어 결제를 진행할 수 없습니다.");
                        return false;
                    }

                    form.target = "nice-payu";
                }

                form.method = "post";
                form.submit();
            });
        },
    },
};
</script>
