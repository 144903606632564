var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "method": "post",
      "action": "https://payu.nicepay.co.kr/main.do",
      "accept-charset": "euc-kr"
    }
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "MID"
    },
    domProps: {
      "value": _vm.mid
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "MallUserID"
    },
    domProps: {
      "value": _vm.mallUserId
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "UserKey"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "LogoImgUrl"
    },
    domProps: {
      "value": _vm.logoUrl
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "EdiDate"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "PageType",
      "value": "MyPage"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "ReturnUrl"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "SignData"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "SkinType",
      "value": "dark"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "CharSet",
      "value": "euc-kr"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }